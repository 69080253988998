<template>
  <div class="cashapp-container">
    <h1 class="cashapp-header">Payment Center</h1>

    <div class="order-details">
      <p>Amount: <span class="order-info">$ {{ (amount/100).toFixed(2) }} </span></p>
    </div>

    <form id="payment-form" @submit.prevent="handlePayment">
      <div id="payment-element"></div>
<!--      <button type="submit" class="cashapp-button">Waiting for payment</button>-->
      <div id="error-message"></div>
    </form>
    <div class="btn-wrap" @click.stop="handlePayment">Pay Now</div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import apiClient, {API_URL_CREATE_PAYMENT} from '@/services/api'; // 引入封装好的 HTTP 请求

export default {
  data() {
    return {
      stripe: null,
      clientSecret: null,
      elements: null,
      amount: null,  // 从 URL 查询参数中获取传入的 amount
      encryptedString: this.$route.params.encryptedString,  // 从 URL 获取加密串
      orderId: null, // 从 URL 查询参数中获取传入的 orderId
      returnUrl: null
    };
  },
  async mounted() {
    document.title = 'Payment Center';
    try {
      const response = await apiClient.post(API_URL_CREATE_PAYMENT, {
        encryptedString: this.encryptedString
      });
      this.clientSecret = response.data.clientSecret;
      this.amount = response.data.amount;
      this.orderId = response.data.orderId;
      this.wayCode = response.data.wayCode;
      this.returnUrl = response.data.returnUrl;
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

      const elements = this.stripe.elements({ clientSecret: this.clientSecret });
      const paymentElement = elements.create('payment');
      paymentElement.mount('#payment-element');
      this.elements = elements;

      // 1 秒后自动提交表单
      // setTimeout(() => {
        if(this.wayCode === 'cashapp'){
          this.handleCashappPayment();
        }    
      // }, 100);
    } catch (error) {
      console.error('error', error);
    }
  },
  methods: {
    async handleCashappPayment() {
      try {
        const {error} = await this.stripe.confirmCashappPayment(this.clientSecret, {
          payment_method: {
            cashapp: {}  // use Cash App payment method
          },
          return_url: this.returnUrl, // return url after payment success
        });

        if (error) {
          // 如果支付发生错误，显示错误信息
          document.getElementById('error-message').textContent = error.message;
        }
      } catch (error) {
        console.error('pay failed:', error);
      }
    },
    async handlePayment() {
      try {
        const elements = this.elements;
        this.stripe.confirmPayment({
          elements,
          confirmParams: {
            // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
            return_url: this.returnUrl,
          },
        })
        .then(function(result) {
          if (result.error) {
            // Inform the customer that there was an error.
          }
        });
      } catch (error) {
        console.error('pay failed:', error);
      }
    },
  },
};
</script>

<style scoped>
/* 容器布局 */
.cashapp-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* 标题样式 */
.cashapp-header {
  color: #00d632; /* Cash App 绿色 */
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
}

/* 订单详情样式 */
.order-details {
  margin-bottom: 20px;
  font-size: 1.2em;
}

/* 订单信息样式 */
.order-info {
  font-weight: bold;
  color: #333;
}

/* 按钮样式 */
.cashapp-button {
  background-color: #00d632; /* Cash App 绿色 */
  color: white;
  padding: 15px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.cashapp-button:hover {
  background-color: #00b828; /* 深绿色悬停效果 */
}

/* 错误信息样式 */
#error-message {
  color: red;
  margin-top: 10px;
}
.btn-wrap {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  border-radius: 10px;
  background-color: #00b828;
  color: white;
  box-sizing: border-box;
  margin-top: 20px;
}
</style>
