import Vue from 'vue';
import VueRouter from 'vue-router';
import CashAppPay from '@/components/CashAppPay.vue'; // 引入支付组件

Vue.use(VueRouter);

const routes = [
    {
        path: '/checkout/:encryptedString',
        name: 'CashAppPay',
        component: CashAppPay,  // 绑定 CashAppPay 组件
    },
    // 其他路由...
];

const router = new VueRouter({
    mode: 'history',
    routes
});

// 全局路由守卫设置默认标题
router.beforeEach((to, from, next) => {
    // 如果有指定 title 就使用，没有就设置默认值
    document.title = to.meta.title || 'title';
    next();
});

export default router;
